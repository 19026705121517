import { url } from "@/config"
import request from "../axios"
export const realmSmsCode = (data) =>
  request({
    url: url + "/realms/realmSmsCode",
    method: "post",
    data: data,
  })

export const realmlogin = (data) =>
  request({
    url: url + "/realms/realmlogin",
    method: "post",
    data: data,
  })

export const getRealmlDetail = (realmlame) =>
  request({
    url: url + `/realms/${realmlame}`,
    method: "get",
  })
