<template>
  <div class="pwReset">
    <dv-border-box-12
      style=""
      class="card">
      <h1 style="text-align: center; color: #fff">忘记密码</h1>
      <el-form
        :model="ruleForm"
        status-icon
        ref="ruleForm"
        class="demo-ruleForm">
        <el-form-item prop="pass">
          <el-input
            placeholder="请输入手机号"
            v-model="ruleForm.pass"
            autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="pass">
          <el-input
            type="password"
            placeholder="请输入密码"
            v-model="ruleForm.phone"
            autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="pass">
          <el-input
            type="password"
            placeholder="请再次输入密码"
            v-model="ruleForm.phone"
            autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item style="text-align: center">
          <el-button
            style="width: 100%"
            type="primary"
            @click="submitForm('ruleForm')">
            重置
          </el-button>
          <el-button
            @click="$router.push('/login')"
            type="text"
            style="float: right">
            登录
          </el-button>
          <!-- <el-button type="text">忘记密码</el-button>
          <el-button
            type="text"
            @click="$router.push('/register')">
            立刻注册
          </el-button> -->
          <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
          <!-- <el-button @click="resetForm('ruleForm')">注册</el-button> -->
        </el-form-item>
      </el-form>
    </dv-border-box-12>

    <img
      :src="`${publicPath}img/bg/bg12.jpg`"
      alt=""
      width="100%" />
  </div>
</template>
<script>
import navs from "./nav/index01"
export default {
  name: "index",
  components: {
    navs,
  },
  data() {
    return {
      publicPath: process.env.VUE_APP_PATH,
      ruleForm: {
        pass: "",
        checkPass: "",
        type: "",
      },
    }
  },
  mounted() {
    // this.$notify({
    //   dangerouslyUseHTMLString: true,
    //   title: '可视化数据大屏（演示环境-请勿放生产数据）',
    //   message: `点击购买`,
    //   duration: 0,
    //   onClick: () => {
    //     window.open('https://avuejs.com/views/pay/')
    //   }
    // });
  },
  methods: {
    handleChange(item, index) {
      this.$router.push({ path: item.path })
    },
  },
}
</script>
<style lang="scss" scope>
.el-form-item__content {
  width: 100%;
}
.pwReset {
  .card {
    position: fixed;
    width: 400px;
    height: 400px;
    background: #0f0e0e80;
    left: 0;
    right: 0;
    margin: 5% auto 0;
    padding: 20px 0 10px;
    //   display: flex;
    //   justify-content: center;
    .demo-ruleForm {
      width: 80%;
      margin: 10% 10% 0;
    }
    .pp {
      font-size: 12px;
      line-height: 20px;
      color: #eee;
    }
  }
}
</style>
