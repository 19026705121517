<template>
  <div class="register">
    <dv-border-box-12
      style=""
      class="card">
      <h1 style="text-align: center; color: #fff">注册</h1>
      <el-form
        :model="ruleForm"
        status-icon
        ref="ruleForm"
        class="demo-ruleForm">
        <el-form-item prop="pass">
          <el-input
            placeholder="请输入手机号"
            v-model="ruleForm.pass"
            autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="pass">
          <el-input
            type="password"
            placeholder="请输入密码"
            v-model="ruleForm.phone"
            autocomplete="off"></el-input>
          <p class="pp">@密码长度为8-20位</p>
          <p class="pp">@只能包合大小写字母、数字以及符号(不合空格)</p>
          <p class="pp">@大写字母，小写字母，教字和符号至少包含3种</p>
        </el-form-item>
        <el-form-item prop="pass">
          <el-input
            type="password"
            placeholder="请再次输入密码"
            v-model="ruleForm.phone"
            autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item prop="type">
          <el-checkbox-group v-model="ruleForm.type">
            <el-checkbox name="type">
              我同意并遵守上还的《拼多多开放平台开发者服务协议》
            </el-checkbox>
            <el-checkbox name="type">
              <div>我同意并承诺不会利用拼多多官方名义及相关产品名称进行宣传，</div>
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item style="text-align: center">
          <el-button
            style="width: 100%"
            type="primary"
            @click="submitForm('ruleForm')">
            注册
          </el-button>
          <!-- <el-button type="text">忘记密码</el-button>
          <el-button
            type="text"
            @click="$router.push('/register')">
            立刻注册
          </el-button> -->
          <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
          <!-- <el-button @click="resetForm('ruleForm')">注册</el-button> -->
        </el-form-item>
      </el-form>
    </dv-border-box-12>

    <img
      :src="`${publicPath}img/bg/bg12.jpg`"
      alt=""
      width="100%" />
  </div>
</template>
<script>
import navs from "./nav/index01"
export default {
  name: "index",
  components: {
    navs,
  },
  data() {
    return {
      publicPath: process.env.VUE_APP_PATH,
      ruleForm: {
        pass: "",
        checkPass: "",
        type: "",
      },
    }
  },
  mounted() {
    // this.$notify({
    //   dangerouslyUseHTMLString: true,
    //   title: '可视化数据大屏（演示环境-请勿放生产数据）',
    //   message: `点击购买`,
    //   duration: 0,
    //   onClick: () => {
    //     window.open('https://avuejs.com/views/pay/')
    //   }
    // });
  },
  methods: {
    handleChange(item, index) {
      this.$router.push({ path: item.path })
    },
  },
}
</script>
<style lang="scss" scope>
.el-form-item__content {
  width: 100%;
}
.register {
  .card {
    position: fixed;
    width: 500px;
    height: 600px;
    background: #0f0e0e80;
    left: 0;
    right: 0;
    margin: 5% auto 0;
    padding: 20px 0 10px;
    //   display: flex;
    //   justify-content: center;
    .demo-ruleForm {
      width: 80%;
      margin: 10% 10% 0;
    }
    .pp {
      font-size: 12px;
      line-height: 20px;
      color: #eee;
    }
  }
}
.index {
  height: 100%;
  .header {
    position: relative;
    .nav {
      margin: 0 20px;
      width: 100%;
      position: absolute;
      bottom: 10px;
      border: none;
      .el-menu-item {
        background-color: rgba(0, 0, 0, 0) !important;
      }
    }
    .title {
      position: absolute;
      top: 60px;
      left: 20px;
      font-size: 48px;
      font-style: oblique;
      color: rgb(0, 186, 255);
      font-weight: bold;
      line-height: 35px;
    }
    .title small {
      font-size: 18px;
      color: #9cb4c2;
    }
  }
  .main {
    width: 100%;
    height: calc(100% - 220px);
  }
}
</style>
