import { url } from "@/config"
import request from "../axios"

export const createClient = (data) =>
  request({
    url: url + "/clients/createClient",
    method: "post",
    data: data,
  })

export const getRealmlDetailName = (realmName) =>
  request({
    url: url + `/clients/getAllClients`,
    method: "post",
    data:{realmName}
  })
export const getRealmlDetail = (id) =>
  request({
    url: url + `/clients/${id}`,
    method: "get",
  })
