<template>
	<avue-crud
		:option="option"
		:data="data"
		:page.sync="page"
		:before-open="beforeOpen"
		v-model="form"
		ref="crud"
		@row-update="rowUpdate"
		@row-save="rowSave"
		@row-del="rowDel"
		@refresh-change="refreshChange"
		@on-load="onLoad"
	>
		<!-- <template slot="menuForm">
      <el-button
        type="primary"
        size="small"
        @click="handleTest()">
        测试连接
      </el-button>
    </template> -->
		<!-- <template
      slot="menu"
      slot-scope="{ row, size, type }">
      <el-button
        icon="el-icon-check"
        :size="size"
        :type="type"
        @click="handleTest(row)">
        测试连接
      </el-button>
    </template> -->
	</avue-crud>
</template>

<script>
import { getList, getDetail, add, update, remove, dbTest } from '@/api/db';
import { createClient, getRealmlDetailName } from '@/api/clients';
import { mapState, mapMutations } from 'vuex';
const dicData = [
	{
		label: 'mysql',
		value: 'com.mysql.cj.jdbc.Driver',
		url: 'jdbc:mysql://localhost:3306/bladex_report',
	},
	{
		label: 'sqlServer',
		value: 'com.microsoft.sqlserver.jdbc.SQLServerDriver',
		url: 'jdbc:sqlserver://127.0.0.1:1433;DatabaseName=bladex',
	},
	{
		label: 'postgreSql',
		value: 'org.postgresql.Driver',
		url: 'jdbc:postgresql://127.0.0.1:5432/bladex',
	},
	{
		label: 'oracle',
		value: 'oracle.jdbc.OracleDriver',
		url: 'jdbc:oracle:thin:@127.0.0.1:1521:orcl',
	},
];
export default {
	data() {
		return {
			isEdit: false,
			form: {},
			query: {},
			page: {
				pageSize: 10,
				currentPage: 1,
				total: 0,
			},
			option: {
				index: true,
				copyBtn: false,
				labelWidth: 110,
				menuWidth: 280,

				column: [
					{
						label: '应用名称',
						prop: 'clientId',
						// width: 220,
						rules: [
							{
								required: true,
								message: '请输入应用名称',
								trigger: 'blur',
							},
						],
					},
					{
						label: 'APPID',
						prop: 'clientId',
						// width: 220,
						display: false,
						rules: [
							{
								required: false,
								trigger: 'blur',
							},
						],
					},
					{
						label: 'APPKEY',
						prop: 'secret',
						// width: 320,
						display: false,
						rules: [
							{
								required: false,
								trigger: 'blur',
							},
						],
					},
				],
			},
			data: [],
		};
	},
	computed: {
		...mapState(['userData', 'isLogin']),
	},
	watch: {
		isLogin(val) {
			if (val) {
				this.onLoad();
			}
		},
		'form.driverClass'(val) {
			if (this.isEdit) {
				this.isEdit = false;
				return;
			}
			let obj = dicData.find((ele) => ele.value == val) || {};
			this.form.url = obj.url;
		},
	},
	methods: {
		...mapMutations(['showLogin']),
		vaildData(id) {
			return [0, 1, 2, 3].includes(id);
		},
		rowSave(row, done, loading) {
			createClient(row).then(
				() => {
					this.onLoad();
					this.$message({
						type: 'success',
						message: '操作成功!',
					});
					done();
				},
				(error) => {
					window.console.log(error);
					loading();
				}
			);
		},
		rowUpdate(row, index, done, loading) {
			if (this.vaildData(index) && this.$website.isDemo) {
				this.$message.error(this.$website.isDemoTip);
				done();
				return false;
			}
			update(row).then(
				() => {
					done();
					this.onLoad();
					this.$message({
						type: 'success',
						message: '操作成功!',
					});
				},
				(error) => {
					window.console.log(error);
					loading();
				}
			);
		},
		rowDel(row, index) {
			if (this.vaildData(index) && this.$website.isDemo) {
				this.$message.error(this.$website.isDemoTip);
				return false;
			}
			this.$confirm('确定将选择数据删除?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					return remove(row.id);
				})
				.then(() => {
					this.onLoad();
					this.$message({
						type: 'success',
						message: '操作成功!',
					});
				});
		},
		beforeOpen(done, type) {
			if (['edit', 'view'].includes(type)) {
				getDetail(this.form.id).then((res) => {
					this.form = res.data.data;
				});
			}
			this.isEdit = true;
			done();
		},
		refreshChange() {
			this.onLoad();
		},
		handleTest(form) {
			form = form || this.form;
			dbTest({
				driverClass: form.driverClass,
				url: form.url,
				username: form.username,
				password: form.password,
			}).then((res) => {
				const data = res.data;
				if (data.success) {
					this.$message.success('连接成功');
				} else {
					this.$message.error('连接失败，请检查相关配置参数');
				}
			});
		},
		onLoad() {
			console.log(this.userData.realmName);
			getRealmlDetailName(this.userData?.realmName).then((res) => {
				console.log(res.data, '11111111111');
				// if(res.data.code==200){
				const { clientId, clientId1,secret } = res.data[0];
				// this.page.total = data.total
				this.data = res.data; // data.records
				// }else{
				//   this.$message.error(res.data.msg)
				// }
			});
			// getList({
			//   current: this.page.currentPage,
			//   size: this.page.pageSize,
			// }).then((res) => {
			//   const data = res.data.data
			//   this.page.total = data.total
			//   this.data = data.records
			// })
		},
	},
};
</script>

<style></style>
