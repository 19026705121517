<template>
	<div class="index">
		<div class="header">
			<img :src="`${publicPath}img/nav-img.png`" alt="" width="100%" height="160px" />
			<div class="title">
				<p>
					{{ $website.name }}
					<br />
					<small>{{ $website.subName }}</small>
				</p>
			</div>
			<navs @change="handleChange"></navs>
		</div>

		<el-button
			v-if="!userData?.realmName"
			style="position: fixed; top: 20px; right: 20px; font-size: 18px; z-index: 999"
			@click="showLogin(true)"
			type="text"
		>
			登录
		</el-button>

		<el-button
			v-else
			style="position: fixed; top: 20px; right: 105px; font-size: 18px; z-index: 999"
			@click="$router.push('/person')"
			type="text"
		>
			{{ userData?.realmName }}
		</el-button>
		<el-button
			v-if="userData.realmName"
			style="position: fixed; top: 20px; right: 20px; font-size: 18px; z-index: 999"
			@click="LoginOut"
			type="text"
		>
			退出登录
		</el-button>
		<el-scrollbar class="main">
			<Login v-if="isLogin" @close="() => showLogin(false)" />
			<router-view v-else />
		</el-scrollbar>
	</div>
</template>
<script>
import Login from './login.vue';
import navs from './nav/index01';
import { mapState, mapMutations } from 'vuex';

export default {
	name: 'index',
	components: {
		Login,
		navs,
	},
	data() {
		return {
			publicPath: process.env.VUE_APP_PATH,
		};
	},
	computed: {
		...mapState(['userData', 'isLogin']),
		curRoute() {
			return this.$route.path;
		},
	},
	watch: {
		curRoute(val) {
			const route = ['/db', '/person'];
			if (route.includes(val)) {
				if (!this.userData.realmName) {
					this.showLogin(true);
				}
			} else {
				this.showLogin(false);
			}
		},
		'userData.realmName': {
			handler(val) {
				console.log(val, 'val');
				const route = ['/db', '/person'];
				console.log(this.curRoute, route.includes(this.curRoute), 'route');
				if (route.includes(this.curRoute)) {
					if (!val) {
						this.showLogin(true);
					}
				} else {
					this.showLogin(false);
				}
			},
			immediate: true,
		},
	},
	created() {
		const data = localStorage.getItem('loginstore');
		console.log(data, 'loginstore');
		if (data) {
			this.setUserData(JSON.parse(data));
		}
		// this.$store.commit("showLogin", true)
		// console.log(this.$store, "userData")
		// this.$notify({
		//   dangerouslyUseHTMLString: true,
		//   title: '可视化数据大屏（演示环境-请勿放生产数据）',
		//   message: `点击购买`,
		//   duration: 0,
		//   onClick: () => {
		//     window.open('https://avuejs.com/views/pay/')
		//   }
		// });
	},
	methods: {
		...mapMutations(['showLogin', 'setUserData', 'LoginOut']),

		handleChange(item, index) {
			this.$router.push({ path: item.path });
		},
	},
};
</script>
<style lang="scss">
.index {
	height: 100%;
	.header {
		position: relative;
		.nav {
			margin: 0 20px;
			width: 100%;
			position: absolute;
			bottom: 10px;
			border: none;
			.el-menu-item {
				background-color: rgba(0, 0, 0, 0) !important;
			}
		}
		.title {
			position: absolute;
			top: 60px;
			left: 20px;
			font-size: 48px;
			font-style: oblique;
			color: rgb(0, 186, 255);
			font-weight: bold;
			line-height: 35px;
		}
		.title small {
			font-size: 18px;
			color: #9cb4c2;
		}
	}
	.main {
		width: 100%;
		height: calc(100% - 220px);
	}
}
</style>
