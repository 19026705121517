<template>
	<!-- <el-container
    class="list"
    :style="{ height: setPx(height) }"> -->
	<div style="color: #fff">
		<div class="card">
			<h2 style="margin-bottom: 20px">{{ userObj.phone }}</h2>
			<el-button @click="LoginOut">退出登录</el-button>
		</div>
		<div style="display: flex">
			<div class="card">
				<h3 style="margin-bottom: 20px">账户信息</h3>
				<p>
					租户id:
					<span>{{ userObj.userId }}</span>
				</p>
				<p>
					租户名称:
					<span>{{ userObj.userName }}</span>
				</p>
				<p>
					电话:
					<span>{{ userObj.phone }}</span>
				</p>
				<p>
					邮箱:
					<span>{{ userObj.emil }}</span>
				</p>
			</div>
			<div class="card">
				<h3 style="margin-bottom: 20px">沟通工具</h3>
				开发平台登录手机号 {{ userObj.phone }}
			</div>
		</div>
	</div>
	<!-- </el-container> -->
</template>
<script>
// import { getList, updateObj, delObj, getCategory, copyObj } from "@/api/visual"
import { getRealmlDetail } from '@/api/user';
// import { url } from "@/config"
import { mapMutations, mapState } from 'vuex';
export default {
	name: 'list',
	data() {
		return {
			publicPath: process.env.VUE_APP_PATH,
			active: 0,
			height: 0,
			typeList: [],
			box: false,

			list: [],
			userObj: {
				userName: '',
				userId: '',
				phone: '',
				emil: '',
			},
		};
	},

	computed: {
		...mapState(['userData']),
		imgurl() {
			return `${this.publicPath}img/bg/bg${this.active + 5}.jpg`;
		},
	},
	created() {
		// if (!this.userData?.realmName) {
		//   this.showLogin(true)
		//   return
		// }
		// this.getCategory()
	},
	mounted() {
		this.height = document.body.clientHeight - 230;
		console.log(this.userData, '!this.userData?.realmName');
		if (!this.userData.realmName) {
			// this.showLogin(true)
			return;
		} else {
			this.userObj.userName = this.userData.realmName;
			this.userObj.phone = this.userData.phone;
			getRealmlDetail(this.userData.realmName).then((res) => {
				// if(res.data.code==200){
				const { realm, displayNameHtml, id } = res.data;
				this.userObj.userId = id;
				this.userObj.userName = realm;
				this.userObj.phone = displayNameHtml;
				// }
			});
		}
	},
	methods: {
		...mapMutations(['showLogin', 'LoginOut']),
	},
};
</script>
<style lang="scss">
@import '@/styles/list.scss';
.card {
	padding: 20px;
	height: auto;
	flex: 1;
	border: 1px solid #999;
	margin: 10px;
	p {
		span {
			display: inline-block;
			margin: 10px;
		}
	}
}
</style>
