<template>
	<el-container class="list" :style="{ height: setPx(height) }">
		<el-aside width="180px">
			<el-container v-if="showButton">
				<dv-decoration-11 style="width: 200px; height: 60px">
					<el-button type="text" @click="openArticleForm">创建API接口</el-button>
				</dv-decoration-11>
				<dv-decoration-11 style="width: 200px; height: 60px">
					<el-button type="text" @click="creatClassifyForm = true">创建API分类</el-button>
				</dv-decoration-11>
			</el-container>

			<ul
				class="infinite-list menu"
				v-infinite-scroll="load"
				style="overflow: auto; padding-top: 4px; border-right: 1px solid #ccc"
			>
				<li v-if="!showButton" style="color: rgb(64, 158, 255); font-size: 16px; height: auto">
					<i class="el-icon-menu"></i>
					接口分类
					<!-- <dv-decoration-11 v-if="!showButton" style="height: 60px; width: 120px; color: #409eff"
						>接口分类</dv-decoration-11
					> -->
				</li>
				<li
					:class="{ 'is-active': index === activebb }"
					@click="getArticleForm(aaa.id, index)"
					v-for="(aaa, index) in classifyList"
				>
					{{ aaa.classify }}
				</li>
			</ul>
		</el-aside>
		<el-aside width="400px">
			<p
				v-if="!showButton"
				style="color: rgb(64, 158, 255); font-size: 16px; height: 50px; line-height: 50px; padding-left: 40px"
			>
				<i class="el-icon-document"></i>
				接口标题
			</p>

			<!-- <dv-decoration-11 v-if="!showButton" style="height: 60px; width: 230px; color: #409eff">
				接口标题</dv-decoration-11
			> -->
			<ul class="infinite-list menu">
				<li
					:key="index"
					v-for="(item, index) in article"
					@click="getArticleDetilForm(item.id, index)"
					:class="{ 'is-active': index === active }"
				>
					{{ item.articleTitle }}
				</li>
			</ul>
		</el-aside>
		<el-container
			class="infinite-list"
			v-infinite-scroll="load"
			style="background: linear-gradient(to right, rgb(128, 128, 128), pink); overflow: auto; width: 100%"
		>
			<dv-decoration-11 style="width: 200px; height: 60px; position: absolute; right: 0" v-if="showButton">
				<el-button type="text" @click="updateArticleFormButton">修改文章</el-button>
			</dv-decoration-11>
			<div>
				<div style="margin-left: 50px; margin-top: 50px" v-html="articleContent.articleContent" />
			</div>
		</el-container>
		<el-dialog title="创建API接口" :visible.sync="createArticleForm">
			<el-form :model="form">
				<el-form-item label="接口名称">
					<el-input v-model="articleForm.articleTitle" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="选择分类">
					<el-select v-model="articleForm.classifyId" placeholder="请选择分类">
						<el-option v-for="item in classifyList" :key="item.id" :label="item.classify" :value="item.id"> </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="API接口明细">
					<quillEditor v-model="articleForm.articleContent" />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="createArticleForm = false">取 消</el-button>
				<el-button type="primary" @click="saveArticleForm">确 定</el-button>
			</div>
		</el-dialog>

		<el-dialog title="创建API分类" :visible.sync="creatClassifyForm">
			<el-form :model="form">
				<el-form-item label="分类名称">
					<el-input v-model="classifyForm.classify"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="creatClassifyForm = false">取 消</el-button>
				<el-button type="primary" @click="saveClassifyListForm">确 定</el-button>
			</div>
		</el-dialog>

		<el-dialog title="修改API接口" :visible.sync="openUpdateArticleForm">
			<el-form :model="form">
				<el-form-item label="接口名称">
					<el-input v-model="articleForm.articleTitle" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="选择分类">
					<el-select v-model="articleForm.classifyId" placeholder="请选择分类">
						<el-option v-for="item in classifyList" :key="item.id" :label="item.classify" :value="item.id"> </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="API接口明细">
					<quillEditor v-model="articleForm.articleContent" />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="openUpdateArticleForm = false">取 消</el-button>
				<el-button type="primary" @click="updateArticleForm">确 定</el-button>
			</div>
		</el-dialog>
	</el-container>
</template>
<script>
import {
	getList,
	updateObj,
	delObj,
	getCategory,
	copyObj,
	getClassifyList,
	getArticleList,
	saveClassify,
	saveArticle,
	getArticleDetil,
	updateArticle,
	ifPermissions,
} from '@/api/visual';
import { getObj } from '@/api/visual';
import { url } from '@/config';
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

export default {
	components: { quillEditor },
	name: 'list',
	data() {
		return {
			showButton: false,
			openUpdateArticleForm: false,
			articleContent: '',
			article: null,
			activebb: null,
			classifyForm: {
				classify: null,
			},
			articleForm: {
				articleTitle: '',
				classifyId: '',
				articleContent: '',
			},
			createArticleForm: false,
			creatClassifyForm: false,
			classifyList: [],
			publicPath: process.env.VUE_APP_PATH,
			active: 0,
			height: 0,
			typeList: [],
			box: false,
			option: {
				submitText: '修改大屏',
				column: [
					{
						label: '分组',
						prop: 'category',
						span: 24,
						labelWidth: 100,
						type: 'select',
						dicUrl: url + '/category/list',
						props: {
							label: 'categoryKey',
							value: 'categoryValue',
						},
						rules: [
							{
								required: true,
								message: '请选择分组',
								trigger: 'blur',
							},
						],
					},
					{
						label: '大屏名称',
						span: 24,
						labelWidth: 100,
						prop: 'title',
						rules: [
							{
								required: true,
								message: '请输入大屏名称',
								trigger: 'blur',
							},
						],
					},
					{
						label: '密码',
						span: 24,
						type: 'password',
						labelWidth: 100,
						prop: 'password',
					},
					{
						label: '发布状态',
						prop: 'status',
						span: 24,
						labelWidth: 100,
						type: 'select',
						dicData: [
							{
								label: '未发布',
								value: 0,
							},
							{
								label: '已发布',
								value: 1,
							},
						],
					},
				],
			},
			page: {
				page: 1,
				size: 50,
				total: 0,
			},
			form: {},
			activeName: '',
			list: [],
		};
	},

	computed: {
		imgurl() {
			return `${this.publicPath}img/bg/bg${this.active + 5}.jpg`;
		},
	},
	created() {
		this.getCategory();
		this.getClassifyListPage();
	},
	mounted() {
		this.height = document.body.clientHeight - 230;
	},
	methods: {
		updateArticleFormButton() {
			this.openUpdateArticleForm = true;
			this.articleForm = this.articleContent;
		},
		updateArticleForm() {
			updateArticle(this.articleForm).then((r) => {
				if (r.data) {
					this.openUpdateArticleForm = false;
					this.$message({
						message: '修改文章成功',
						type: 'success',
					});
				}
			});
		},
		openArticleForm() {
			this.articleForm.articleContent = '';
			this.articleForm.articleTitle = '';
			this.articleForm.classifyId = '';
			this.createArticleForm = true;
		},
		getArticleDetilForm(id, index) {
			this.active = index;
			let data = {
				id: id,
			};
			getArticleDetil(data).then((r) => {
				this.articleContent = r.data;
			});
		},
		getArticleForm(id, index) {
			this.activebb = index;
			this.active = index;
			let data = {
				classifyId: id,
			};
			getArticleList(data).then((r) => {
				console.log('r', r.data);
				this.article = r.data;
				this.getArticleDetilForm(r.data[0]?.id, 0);
			});
		},
		saveArticleForm() {
			saveArticle(this.articleForm).then((r) => {
				if (r.data) {
					this.createArticleForm = false;
					this.$message({
						message: '创建标签成功',
						type: 'success',
					});
				}
			});
		},
		saveClassifyListForm() {
			saveClassify(this.classifyForm).then((r) => {
				if (r.data) {
					this.creatClassifyForm = false;
					this.$message({
						message: '创建分类成功',
						type: 'success',
					});
					this.getClassifyListPage();
				}
			});
		},
		getClassifyListPage() {
			let absba = JSON.parse(localStorage.getItem('loginstore'));
			console.log({ absba });
			this.showButton = absba?.realmName === 'admin';
			// ifPermissions(absba.realmName).then((r) => {
			// 	if (r.data) {
			// 		this.showButton = true;
			// 	}
			// });
			getClassifyList().then((r) => {
				this.classifyList = r.data;
				let data = {
					classifyId: this.classifyList[0].id,
				};
				getArticleList(data).then((r) => {
					console.log('r', r.data);
					this.article = r.data;
					let dataa = {
						id: this.article[0]?.id,
					};
					getArticleDetil(dataa).then((r) => {
						this.articleContent = r.data;
					});
				});
			});
		},
		handleSelect(key) {
			this.activeName = key;
			this.page.page = 1;
			this.getList();
		},
		vaildData(id) {
			const list = [];
			for (var i = 0; i < 20; i++) {
				list.push(i);
			}
			return list.includes(id);
		},
		getCategory() {
			getCategory().then((res) => {
				const data = res.data.data;
				this.typeList = data;
				this.activeName = (data[0] || {}).categoryValue;
				this.getList();
			});
		},
		handleExport(item) {
			getObj(item.id).then((res) => {
				this.$message.success('大屏导出成功');
				const data = res.data.data;
				let mode = {
					detail: JSON.parse(data.config.detail),
					component: JSON.parse(data.config.component),
				};
				var zip = new window.JSZip();
				zip.file('view.js', `const option =${JSON.stringify(mode, null, 4)}`);
				zip.file('说明.txt', `把view.js替换index.zip中文件即可,运行index.html即可看到效果`);
				zip.generateAsync({ type: 'base64' }).then((content) => {
					this.downFile('data:application/zip;base64,' + content, 'view.zip');
					setTimeout(() => {
						location.href = '/index.zip';
					}, 1000);
				});
			});
		},
		handleCopy(item) {
			if (this.$website.isDemo) {
				this.$message.error(this.$website.isDemoTip);
				return;
			}
			this.$confirm('确认复制当前大屏', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					copyObj(item.id).then((res) => {
						this.$message.success('复制成功');
						const id = res.data.data;
						this.handleEdit({ id });
					});
				})
				.catch(() => {});
		},
		handleDel(item, index) {
			if (this.vaildData(index) && this.$website.isDemo) {
				this.$message.error(this.$website.isDemoTip);
				return false;
			}
			this.$confirm('是否确认永久删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					delObj(item.id).then(() => {
						this.list.splice(index, 1);
						this.$message.success('删除成功');
					});
				})
				.catch(() => {});
		},
		handleAdd() {
			this.$router.push({
				path: '/create',
				query: {
					category: this.activeName,
				},
			});
		},
		handleUpdate(item, index) {
			if (this.vaildData(Number(index)) && this.$website.isDemo) {
				this.$message.error(this.$website.isDemoTip);
				return false;
			}
			this.form = item;
			this.form.category = this.form.category + '';
			this.box = true;
		},
		handleEdit(item) {
			let routeUrl = this.$router.resolve({
				path: '/build/' + item.id,
			});
			window.open(routeUrl.href, '_blank');
		},
		handleViews(item) {
			let routeUrl = this.$router.resolve({
				path: '/view/' + item.id,
			});
			window.open(routeUrl.href, '_blank');
		},
		handleSave(form, done) {
			updateObj({
				id: this.form.id,
				category: this.form.category,
				password: this.form.password,
				status: this.form.status,
				title: this.form.title,
			}).then(() => {
				done();
				this.$message.success('修改成功');
				this.getList();
			});
		},
		handleCurrentChange(val) {
			this.page.page = val;
			this.getList();
		},
		handleSizeChange(val) {
			this.page.size = val;
			this.getList();
		},
		getList(category) {
			this.list = [];
			getList({
				category: category || this.activeName,
				current: this.page.page,
				size: this.page.size,
			}).then((res) => {
				const data = res.data.data;
				this.page.total = data.total;
				this.list = data.records;
				this.initData();
			});
		},
		initData() {
			this.list.forEach((ele, index) => {
				this.$set(this.list[index], '_menu', false);
			});
		},
	},
};
</script>
<style lang="scss">
@import '@/styles/list.scss';
</style>
