<template>
	<div style="background-color: #fff">
		<img :src="`${publicPath}img/home/home_ban2.jpg`" width="100%" />
		<div style="height: 160px; overflow: hidden; position: relative; width: 100%">
			<el-image
				:src="`${publicPath}img/home/home_ban3.jpg`"
				style="position: absolute; bottom: 0; width: 100%"
				fit="cover"
			></el-image>
		</div>
		<div style="padding: 100px 0; width: 100%; background-color: #fff" id="neirong2">
			<div style="text-align: center">
				<h1
					class="i18n"
					data-properties="home_data_slogan"
					style="font-size: 48px; font-weight: 700; color: #0b1d57"
					data-ptype="text"
				>
					引领全球电商布局
				</h1>
			</div>
			<div class="wrapperZs">
				<ul style="display: flex; justify-content: center">
					<li>
						<div class="holder">
							<div class="icon icon-super">
								<img class="icon_blue" :src="`${publicPath}img/home/totalStorageArea-blue.png`" alt="" />
								<img class="icon_white" :src="`${publicPath}img/home/totalStorageArea.png`" alt="" />
							</div>
							<h3 class="i18n small_num" data-properties="home_data_area_num" data-ptype="text">5万+</h3>
							<p class="i18n" data-properties="home_data_area" data-ptype="text">总仓储面积/平方米</p>
						</div>
					</li>
					<li>
						<div class="holder">
							<div class="icon icon-super">
								<img class="icon_blue" :src="`${publicPath}img/home/ProcessTheParcel-blue.png`" alt="" />
								<img class="icon_white" :src="`${publicPath}img/home/ProcessTheParcel.png`" alt="" />
							</div>
							<h3 class="i18n" data-properties="home_data_bags_num" data-ptype="text">4万+</h3>
							<p class="i18n" data-properties="home_data_bags" data-ptype="text">日均处理包裹</p>
						</div>
					</li>
					<li>
						<div class="holder">
							<div class="icon icon-super">
								<img class="icon_blue" :src="`${publicPath}img/home/coverTheCountry-blue.png`" alt="" />
								<img class="icon_white" :src="`${publicPath}img/home/coverTheCountry.png`" alt="" />
							</div>
							<h3 class="i18n" data-properties="home_data_country_num" data-ptype="text">8+</h3>
							<p class="i18n" data-properties="home_data_country" data-ptype="text">覆盖国家</p>
						</div>
					</li>
					<li>
						<div class="holder">
							<div class="icon icon-super">
								<img class="icon_blue" :src="`${publicPath}img/home/internationalLogistics-blue.png`" alt="" />
								<img class="icon_white" :src="`${publicPath}img/home/internationalLogistics.png`" alt="" />
							</div>
							<h3 class="i18n" data-properties="home_data_trunk_num" data-ptype="text">300+</h3>
							<p class="i18n" data-properties="home_data_trunk" data-ptype="text">全球员工</p>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<img :src="`${publicPath}img/bg/xh-bg.png`" alt="" width="100%" height="100%" />
		<img :src="`${publicPath}img/bg/xh-bg1.png`" style="margin-top: 64px" alt="" width="100%" height="100%" />
		<img :src="`${publicPath}img/bg/xh-bg2.png`" alt="" width="100%" height="100%" />
		<!-- <img :src="`${publicPath}img/bg/bg12.jpg`" alt="" width="100%" height="100%" />
		<img :src="`${publicPath}img/bg/bg12.jpg`" alt="" width="100%" height="100%" />
		<img :src="`${publicPath}img/bg/bg12.jpg`" alt="" width="100%" height="100%" /> -->
	</div>
</template>
<script>
// import navs from "./nav/index01"
export default {
	name: 'index',
	components: {
		// navs,
	},
	data() {
		return {
			publicPath: process.env.VUE_APP_PATH,
		};
	},
	mounted() {
		// this.$notify({
		//   dangerouslyUseHTMLString: true,
		//   title: '可视化数据大屏（演示环境-请勿放生产数据）',
		//   message: `点击购买`,
		//   duration: 0,
		//   onClick: () => {
		//     window.open('https://avuejs.com/views/pay/')
		//   }
		// });
	},
	methods: {
		handleChange(item, index) {
			this.$router.push({ path: item.path });
		},
	},
};
</script>
<style lang="scss">
.index {
	height: 100%;
	.header {
		position: relative;
		.nav {
			margin: 0 20px;
			width: 100%;
			position: absolute;
			bottom: 10px;
			border: none;
			.el-menu-item {
				background-color: rgba(0, 0, 0, 0) !important;
			}
		}
		.title {
			position: absolute;
			top: 60px;
			left: 20px;
			font-size: 48px;
			font-style: oblique;
			color: rgb(0, 186, 255);
			font-weight: bold;
			line-height: 35px;
		}
		.title small {
			font-size: 18px;
			color: #9cb4c2;
		}
	}
	.main {
		width: 100%;
		height: calc(100% - 220px);
	}
}
li {
	list-style: none;
}
.wrapperZs {
	li {
		box-sizing: border-box;
		text-align: center;
		background: #e6f1ff;
		cursor: pointer;
		border-radius: 8px;
		margin: 40px;
		width: 245px;
		height: 235px;
		padding: 45px 0;
		&:hover {
			.icon_blue {
				display: none;
			}
			.icon_white {
				display: unset;
			}
			p {
				color: #fff;
			}
			background: url(http://www.xhsnw.com/images/home/holder-bg.png) no-repeat center center;
		}
	}
}
.holder {
	.icon_white {
		display: none;
	}
	.icon_blue {
		display: unset;
	}
	.icon {
		img {
			width: 48px;
			height: 48px;
		}
	}
	h3 {
		font-size: 36px;
		color: #e95335;
		font-weight: 400;
		margin: 10px 0 14px;
	}
	p {
		font-size: 18px;
		align-items: center;
		color: #0b1d57;
	}
}
</style>
