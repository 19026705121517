<template>
	<!-- <el-container
    class="list"
    :style="{ height: setPx(height) }"> -->
	<div style="color: #fff">
		<div style="display: flex">
			<div class="card">
				<h3>账户净余额</h3>
				<h2 style="margin: 10px">0 元</h2>

				<el-button type="primary ">充值</el-button>
			</div>
			<div class="card">
				<h3>可开发票金额</h3>
				<h2 style="margin: 10px">0 元</h2>
				<el-button type="primary ">开票</el-button>
			</div>
		</div>
		<div class="card">
			<h3>收支明细</h3>
			<avue-crud
				:option="option"
				:data="data"
				:page.sync="page"
				:before-open="beforeOpen"
				v-model="form"
				ref="crud"
				@row-update="rowUpdate"
				@row-save="rowSave"
				@row-del="rowDel"
				@refresh-change="refreshChange"
				@on-load="onLoad"
			></avue-crud>
		</div>
	</div>
	<!-- </el-container> -->
</template>
<script>
import { getList, updateObj, delObj, getCategory, copyObj } from '@/api/visual';
import { getObj } from '@/api/visual';
import { url } from '@/config';
export default {
	name: 'list',
	data() {
		return {
			publicPath: process.env.VUE_APP_PATH,
			active: 0,
			height: 0,
			typeList: [],
			box: false,

			list: [],

			isEdit: false,
			form: {},
			query: {},
			page: {
				pageSize: 10,
				currentPage: 1,
				total: 0,
			},
			option: {
				index: true,
				copyBtn: false,
				addBtn: false,
				labelWidth: 110,
				menuWidth: 280,

				column: [
					{
						label: '应用名称',
						prop: 'name',
						// width: 120,
						rules: [
							{
								required: true,
								message: '请输入数应用名称',
								trigger: 'blur',
							},
						],
					},
					{
						label: '应用ID',
						prop: 'name',
						// width: 120,
						display: false,
						rules: [
							{
								required: false,
								message: '请输入数ClientID',
								trigger: 'blur',
							},
						],
					},
					{
						label: '收支金额',
						prop: 'name',
						// width: 120,
						display: false,
						rules: [
							{
								required: false,
								message: '请输入收支金额',
								trigger: 'blur',
							},
						],
					},
					{
						label: '收支类型',
						prop: 'name',
						// width: 120,
						display: false,
						rules: [
							{
								required: false,
								message: '请输入收支类型',
								trigger: 'blur',
							},
						],
					},
				],
			},
			data: [],
		};
	},

	watch: {
		'form.driverClass'(val) {
			if (this.isEdit) {
				this.isEdit = false;
				return;
			}
			let obj = dicData.find((ele) => ele.value == val) || {};
			this.form.url = obj.url;
		},
	},
	methods: {
		vaildData(id) {
			return [0, 1, 2, 3].includes(id);
		},
		rowSave(row, done, loading) {
			add(row).then(
				() => {
					this.onLoad();
					this.$message({
						type: 'success',
						message: '操作成功!',
					});
					done();
				},
				(error) => {
					window.console.log(error);
					loading();
				}
			);
		},
		rowUpdate(row, index, done, loading) {
			if (this.vaildData(index) && this.$website.isDemo) {
				this.$message.error(this.$website.isDemoTip);
				done();
				return false;
			}
			update(row).then(
				() => {
					done();
					this.onLoad();
					this.$message({
						type: 'success',
						message: '操作成功!',
					});
				},
				(error) => {
					window.console.log(error);
					loading();
				}
			);
		},
		rowDel(row, index) {
			if (this.vaildData(index) && this.$website.isDemo) {
				this.$message.error(this.$website.isDemoTip);
				return false;
			}
			this.$confirm('确定将选择数据删除?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					return remove(row.id);
				})
				.then(() => {
					this.onLoad();
					this.$message({
						type: 'success',
						message: '操作成功!',
					});
				});
		},
		beforeOpen(done, type) {
			if (['edit', 'view'].includes(type)) {
				getDetail(this.form.id).then((res) => {
					this.form = res.data.data;
				});
			}
			this.isEdit = true;
			done();
		},
		refreshChange() {
			this.onLoad();
		},
		handleTest(form) {
			form = form || this.form;
			dbTest({
				driverClass: form.driverClass,
				url: form.url,
				username: form.username,
				password: form.password,
			}).then((res) => {
				const data = res.data;
				if (data.success) {
					this.$message.success('连接成功');
				} else {
					this.$message.error('连接失败，请检查相关配置参数');
				}
			});
		},
		onLoad() {
			getList({
				current: this.page.currentPage,
				size: this.page.pageSize,
			}).then((res) => {
				const data = res.data.data;
				this.page.total = data.total;
				this.data = data.records;
			});
		},
	},
};
</script>
<style lang="scss">
@import '@/styles/list.scss';
.card {
	padding: 10px;
	height: auto;
	flex: 1;
	border: 1px solid #999;
	margin: 10px;
}
</style>
