<template>
	<div class="login">
		<dv-border-box-12 style="" class="card">
			<el-button type="text" class="closebtn" @click="$emit('close')">
				<i style="color: #fff" class="el-dialog__close el-icon el-icon-close"></i>
			</el-button>

			<h1 style="text-align: center; color: #fff">欢迎登录</h1>
			<el-form :model="ruleForm" status-icon ref="ruleForm" class="demo-ruleForm">
				<el-form-item prop="realmName">
					<el-input placeholder="请输入租户名" v-model="ruleForm.realmName" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item prop="phone">
					<el-input placeholder="请输入手机号" v-model="ruleForm.phone" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item prop="smsCode">
					<el-input type="realmName" placeholder="请输入验证码" v-model="ruleForm.smsCode" autocomplete="off">
						<template slot="append">
							<div v-if="time">{{ time0 }}</div>

							<div @click="onCode">验证码</div>
						</template>
					</el-input>
				</el-form-item>

				<el-form-item style="text-align: center">
					<el-button style="width: 100%" type="primary" @click="submitForm('ruleForm')"> 登录 </el-button>
					<!-- <el-button
            type="text"
            @click="$router.push('/pwReset')">
            忘记密码
          </el-button>
          <el-button
            type="text"
            @click="$router.push('/register')">
            立刻注册
          </el-button> -->
					<!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
					<!-- <el-button @click="resetForm('ruleForm')">注册</el-button> -->
				</el-form-item>
			</el-form>
		</dv-border-box-12>

		<!-- <img
      :src="`${publicPath}img/bg/bg12.jpg`"
      alt=""
      width="100%" /> -->
	</div>
</template>
<script>
// import navs from "./nav/index01"
import { realmSmsCode, getRealmlDetail, realmlogin } from '../api/user.js';
import { mapMutations } from 'vuex';
export default {
	name: 'index',
	components: {
		// navs,
	},
	data() {
		return {
			publicPath: process.env.VUE_APP_PATH,
			time: null,
			time0: 20,
			ruleForm: {
				realmName: '',
				phone: '',
				smsCode: '',
			},
		};
	},
	computed: {},
	mounted() {
		// this.$notify({
		//   dangerouslyUseHTMLString: true,
		//   title: '可视化数据大屏（演示环境-请勿放生产数据）',
		//   message: `点击购买`,
		//   duration: 0,
		//   onClick: () => {
		//     window.open('https://avuejs.com/views/pay/')
		//   }
		// });
	},
	methods: {
		...mapMutations(['setUserData', 'showLogin']),

		onCode() {
			realmSmsCode(this.ruleForm).then((res) => {
				if (res.data.code != 200) {
					this.$message({
						type: 'info',
						message: res.data.message,
					});
					return;
				}
				console.log(res);
				this.time0 = 20;
				clearInterval(this.time);
				this.time = setInterval(() => {
					if (this.time0 <= 1) {
						clearInterval(this.time);
						this.time = null;
					}
					this.time0--;
				}, 1000);
			});
		},
		submitForm() {
			realmlogin(this.ruleForm).then((res) => {
				console.log('res', res);
				// this.getRealmlDetail()
				if (res.data.code == 200) {
					// if(true){
					this.$message({
						type: 'success',
						message: '操作成功!',
					});
					this.setUserData(res.data.data);
					this.showLogin(false);
					if (this.$route.path === '/login') {
						this.$router.push({ path: '/' });
					}
					// this.$emit("close", this.ruleForm)
				} else {
					this.$message({
						type: 'info',
						message: res.data.message,
					});
				}
			});
			//
		},
		onDetail() {
			getRealmlDetail(this.ruleForm.realmName)((res) => {});
		},
		handleChange(item, index) {
			this.$router.push({ path: item.path });
		},
	},
};
</script>
<style lang="scss" scope>
.el-form-item__content {
	width: 100%;
}
.closebtn {
	position: absolute;
	right: 20px;
	top: -10px;
}
.login {
	.card {
		position: fixed;
		width: 400px;
		height: 350px;
		background: #0f0e0e80;
		left: 0;
		right: 0;
		margin: 5% auto 0;
		padding: 20px 0 10px;
		//   display: flex;
		//   justify-content: center;
		.demo-ruleForm {
			width: 80%;
			margin: 10% 10% 0;
		}
	}
}
.index {
	height: 100%;
	.header {
		position: relative;
		.nav {
			margin: 0 20px;
			width: 100%;
			position: absolute;
			bottom: 10px;
			border: none;
			.el-menu-item {
				background-color: rgba(0, 0, 0, 0) !important;
			}
		}
		.title {
			position: absolute;
			top: 60px;
			left: 20px;
			font-size: 48px;
			font-style: oblique;
			color: rgb(0, 186, 255);
			font-weight: bold;
			line-height: 35px;
		}
		.title small {
			font-size: 18px;
			color: #9cb4c2;
		}
	}
	.main {
		width: 100%;
		height: calc(100% - 220px);
	}
}
</style>
