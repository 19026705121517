<template>
  <div class="nav-main">
    <canvas
      id="nav-canvas"
      style="position: absolute; z-index: -1; left: 0; right: 0"></canvas>
    <span
      v-for="(item, index) in navs"
      :key="index"
      class="nav-span">
      <a
        class="nav-link"
        :class="{ 'nav-active': index === activeIndex }"
        @click="toggleNav(item, index)">
        <i :class="item.icon"></i>
        {{ item.name }}
      </a>
    </span>
    <!-- <el-button
      style="position: fixed; top: 20px; right: 20px; font-size: 18px"
      @click="showLogin = true"
      type="text">
      登录
    </el-button> -->
    <register v-if="false" />
  </div>
</template>

<script>
// import login from "../login.vue"
// import Register from "../register.vue"
import { NavCanvas } from "./nav-canvas"
import { mapState } from "vuex"
export default {
  components: {},
  data() {
    return {
      nc: null,
      navs: [
        {
          icon: "el-icon-menu",
          name: "首页",
          path: "/",
        },
        {
          icon: "el-icon-thumb",
          name: "授权管理",
          path: "/db",
        },
        {
          icon: "el-icon-thumb",
          name: "菜单管理",
          path: "/db",
        },
        {
          icon: "el-icon-thumb",
          name: "权限管理",
          path: "/db",
        },
        {
          icon: "el-icon-menu",
          name: "大数据模版配置",
          path: "/list",
        },
        {
          icon: "el-icon-set-up",
          name: "操作指引",
          path: "/document",
        },
        // {
        //   icon: "el-icon-suitcase",
        //   name: "费用中心",
        //   path: "/fee",
        // },
        {
          icon: "el-icon-suitcase",
          name: "个人中心",
          path: "/person",
        },
      ],
      activeNav: null,
      activeIndex: 0,
    }
  },
  computed: {
    ...mapState(["userData"]),
  },
  mounted() {
    this.navs = this.navs.filter((ele, index) => {
      return this.$website.tabsList.includes(index)
    })
    let index = this.navs.findIndex((ele) => ele.path === this.$route.path)
    this.activeNav = this.navs[index]
    this.activeIndex = index
    this.nc = new NavCanvas("nav-canvas", ".nav-main .nav-span", this.activeIndex)
  },
  methods: {
    toggleNav(nav, index) {
      this.activeNav = nav
      this.activeIndex = index
      this.nc.toggle(this.activeIndex)
      this.$emit("change", nav, index)
    },
  },
}
</script>

<style lang="scss" scoped>
.nav-main {
  z-index: 1024;
  position: absolute;
  bottom: 8px;
  display: flex;
  width: 100%;
  user-select: none;
  height: 50px;
  .nav-span {
    .nav-link {
      transition: color 0.2s;
      text-decoration: none !important;
      display: block;
      color: #b9c2cc;
      width: auto;
      min-width: 140px;
      line-height: 40px;
      font-size: 14px;
      text-align: left;
      cursor: pointer;
      padding: 0 40px;

      &.nav-active,
      &:hover {
        color: #fff !important;
      }
    }
  }
}
</style>
